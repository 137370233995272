<template>
  <div>
    <loading-page title="Saindo de sua conta..." />
  </div>
</template>

<script>
import LoadingPage from '@/components/loading-page';
import { setGtmUserId } from '@/services/events';
import { getToken, setToken } from '@/services/token';
import { delayInSeconds } from '@/utils/async';

export default {
  components: {
    LoadingPage,
  },
  async mounted() {
    if (getToken()) {
      try {
        // Essa chamada é para poder tratar a tag `AUTH.USER_SESSION_EXPIRED` no interceptor do accounts,
        // qualquer erro diferente disso, que não for tratado pelo interceptor, deve ser ignorado aqui.
        await this.$services.accounts.validate();
      } catch (e) {
        console.error(e);
      }

      // Precisa enviar o evento antes da chamada de logout para que o cookie do id do usuário seja enviado
      this.$services.events.notifyManualLogout();

      const [, res] = await Promise.all([
        this.$socialLogin.logoutAll(),
        this.accountsLogout(),
      ]);

      // se o logout retornar uma nova sessão,
      // é porque a sessão que foi deslogada era uma sessão de impersonate,
      // a nova sessão retornada é a sessão do usuário admin logado anteriormente
      if (res?.id && res?.token) {
        setToken(res.token);
        setGtmUserId(res.id);

        location.href = this.$env.VUE_APP_ADMIN_URL + '/#/?restore_path=true';
        return;
      }

      localStorage.clear();
    }

    this.redirect();
  },
  methods: {
    /**
     * Chama o accounts para remover o cookie de sessão do usuário.
     * Caso dê erro, tentará deslogar repetidamente com intervalo de 3 segundos a mais que o intervalo anterior.
     **/
    async accountsLogout(attempt = 1) {
      try {
        return await this.$services.accounts.logout();
      } catch (e) {
        console.error(e?.message);

        const retryTimeout = attempt * 3;
        console.error(`A ${attempt}ª tentativa de logout falhou. Tentando novamente em ${retryTimeout} segundos.`);

        await delayInSeconds(retryTimeout);
        return this.accountsLogout(attempt + 1);
      }
    },
    redirect() {
      let target = location.origin + '/';

      if (this.$route.query.target) {
        try {
          target = decodeURIComponent(this.$route.query.target);
        } catch (e) {
          console.error(e);
        }
      }

      location.href = target;
    },
  },
};
</script>
